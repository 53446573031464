import { useEmblaCarousel } from 'embla-carousel/react'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import tw, { css } from 'twin.macro'
import { global } from '../styles/global'
import { min } from '../utils/breakpoints'
import { StyleType } from '../utils/prop-types'
import Heading from './heading'
import Image from './image'
import Text from './text'

const Testimonials = ({ testimonials, style }) => {
  const [viewportRef, embla] = useEmblaCarousel({ align: 'start' })
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [scrollSnaps, setScrollSnaps] = useState([])

  const scrollTo = useCallback((index) => embla && embla.scrollTo(index), [embla])

  const onSelect = useCallback(() => {
    setSelectedIndex(embla.selectedScrollSnap())
  }, [embla, setSelectedIndex])

  useEffect(() => {
    if (!embla) return
    onSelect()
    setScrollSnaps(embla.scrollSnapList())
    embla.on('select', onSelect)
  }, [embla, onSelect])

  return (
    <>
      <div
        css={[
          global`layout.container`,
          css`
            ${tw`block overflow-visible`}
            &.is-draggable {
              ${tw`cursor-grab`}
            }
            &.is-dragging {
              ${tw`cursor-grabbing`}
            }
          `,
        ]}
        ref={viewportRef}
      >
        <div css={[tw`flex flex-row flex-nowrap`, style]}>
          {testimonials.map((t, i) => (
            <div
              key={i}
              css={tw`flex flex-col items-center flex-none w-full mx-container-mobile lg:mx-container-lg xl:mx-container-xl xxl:mx-container-xxl`}
            >
              <Heading
                content={t.titleNode}
                headingType="h2"
                style={css`
                  ${tw`text-center text-mobile-3xl leading-sm`}
                  strong {
                    ${tw`leading-none text-mobile-7xl lg:text-6xl`}
                  }
                  ${min.lg} {
                    font-size: 3.5rem;
                  }
                `}
              />
              <Text content={t.descriptionNode} style={tw`mt-8 text-center lg:w-2/3`} />
              <div
                css={tw`
                flex flex-col items-center mt-10
                lg:(flex-row mt-20 space-x-12)`}
              >
                <div css={tw`h-16 order-1 mt-8 lg:(mt-0 order-none)`}>
                  <Image image={t.image} />
                </div>
                <Text
                  content={t.subtitleNode}
                  textType="body-base"
                  style={css`
                    ${tw`text-primary-500 text-opacity-80`}
                    strong {
                      ${tw`text-primary-500`}
                    }
                  `}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div
        css={[
          global`layout.container`,
          tw`flex flex-row items-center justify-center w-full mt-16 space-x-2 lg:mt-16`,
          scrollSnaps.length < 2 && tw`hidden`,
        ]}
      >
        {scrollSnaps.map((_, index) => (
          <button
            key={index}
            onClick={() => scrollTo(index)}
            css={[
              tw`w-24 h-0.5 bg-primary-500 opacity-30 transition-opacity duration-300 ease-in-out`,
              index === selectedIndex && tw`opacity-100`,
            ]}
          />
        ))}
      </div>
    </>
  )
}

Testimonials.propTypes = {
  testimonials: PropTypes.arrayOf(PropTypes.object),
  style: StyleType,
}

export default Testimonials
